import React from 'react'
import NormalLayout from '../components/NormalLayout'
import { PageModelTitle } from '../model/PageModel'
import bigLogo from '../static/images/ab-logo.png'
import { useIntl, Link } from 'gatsby-plugin-intl-v6'

export default Media

function Media() {
  let pageModel = new PageModelTitle('MediaKit', 'abwallet-html')
  return <div>{NormalLayout(Main(), pageModel)}</div>
}

function Main() {
  const intl = useIntl()
  return (
    <div id={'media'}>
      <div className={'media'}>
        <div className={'container'}>
          <h1>{ intl.formatMessage(
              { id: 'MediaKit.text' }, 
              { Media: <span>{intl.formatMessage({ id: 'MediaKit.Media'})}</span> }
            )}</h1>
          <div className={'media-content'}>
            <div>
              <img src={bigLogo} alt='logo' />
            </div>
            <div className={'media-download'}>
              <h2>AB logo</h2>
              <p>{ intl.formatMessage({ id: 'Download logos in various sizes and dimensions' }) }</p>
            </div>
          </div>
          <a href="/download/AB-logo.zip" download >{ intl.formatMessage({ id: 'Download' }) }</a>
        </div>
      </div>
      <div className={'media-h5'}>
        <div className={'container'}>
          <h1>{ intl.formatMessage(
              { id: 'MediaKit.text' }, 
              { Media: <span>{intl.formatMessage({ id: 'MediaKit.Media'})}</span> }
            )}</h1>
          <div className={'media-content'}>
            <img src={bigLogo} alt='logo' />
            <h2>AB logo</h2>
            <p>{ intl.formatMessage({ id: 'Download logos in various sizes and dimensions' }) }</p>
          </div>
          <a href="/download/AB-logo.zip" download >{ intl.formatMessage({ id: 'Download' }) }</a>
        </div>
      </div>
    </div>
  )
}